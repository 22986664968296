import React, { useState } from 'react';
import styled from 'styled-components';
import {FaHome, FaBell, FaUser, FaTrophy} from 'react-icons/fa';
import { FaMessage } from 'react-icons/fa6'; // Import message icon
import { useSelector } from 'react-redux';
import Modal from 'react-modal';
import { useNavigate, useLocation } from 'react-router-dom'; // Import useLocation
import RegisterModal from '../components/RegisterModal';
import LoginModal from '../components/LoginModal';
import { RootState } from '../store/store';

const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 600px;
    padding: 10px;
    background-color: #f8f8f8;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const IconButton = styled.button<{ isActive: boolean }>`  // Define isActive as a prop
    background-color: transparent;
    border: none;
    padding: 10px;
    margin: 5px;
    cursor: pointer;
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        color: ${({ isActive }) => (isActive ? '#f56607' : '#242526')}; /* White by default, orange if active */
        transition: color 0.3s ease;
    }

    &:hover svg {
        color: #f56607;
    }
`;


const Button = styled.button`
    margin: 10px 0;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
        background-color: #0056b3;
    }
`;

const Header = () => {
    const [isChoiceModalOpen, setIsChoiceModalOpen] = useState(false);
    const [isRegisterModalOpen, setIsRegisterModalOpen] = useState(false);
    const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
    const user = useSelector((state: RootState) => state.auth.user);
    const navigate = useNavigate();
    const location = useLocation(); // Get the current location

    const handleIconClick = (path: string) => {
        if (path === '/' || path === '/databaza-tipov') {
            navigate(path);
        } else if (user) {
            navigate(path);
        } else {
            setIsChoiceModalOpen(true);
        }
    };


    const handleLogin = () => {
        setIsChoiceModalOpen(false);
        setIsLoginModalOpen(true);
    };

    const handleRegister = () => {
        setIsChoiceModalOpen(false);
        setIsRegisterModalOpen(true);
    };

    return (
        <HeaderContainer>
            <IconButton
                onClick={() => handleIconClick('/')}
                isActive={location.pathname === '/'} // Check if the current path is active
            >
                <FaHome className="icon-gradient" />
            </IconButton>
            <IconButton
                onClick={() => handleIconClick('/messages')}
                isActive={location.pathname === '/messages'}
            >
                <FaMessage className="icon-gradient" />
            </IconButton>
            <IconButton
                onClick={() => handleIconClick('/databaza-tipov')}
                isActive={location.pathname === '/databaza-tipov'}
            >
                <FaTrophy className="icon-gradient" />
            </IconButton>
            <IconButton
                onClick={() => handleIconClick('/comment-notification')}
                isActive={location.pathname === '/comment-notification'}
            >
                <FaBell className="icon-gradient" />
            </IconButton>
            <IconButton
                onClick={() => handleIconClick('/account')}
                isActive={location.pathname === '/account'}
            >
                <FaUser className="icon-gradient" />
            </IconButton>

            {/* Modals and other parts remain the same */}
            <Modal
                isOpen={isChoiceModalOpen}
                onRequestClose={() => setIsChoiceModalOpen(false)}
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.75)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100vh',
                    },
                    content: {
                        position: 'relative',
                        inset: 'auto',
                        padding: '0',
                        borderRadius: '10px',
                        background: 'none',
                        maxWidth: '400px',
                        width: '90%',
                        height: 'auto',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    },
                }}
                ariaHideApp={false}
            >
                <div
                    style={{
                        background: 'linear-gradient(135deg, #f56607, #000)',
                        padding: '30px',
                        borderRadius: '10px',
                        textAlign: 'center',
                    }}
                >
                    <Button
                        style={{
                            marginBottom: '20px',
                            padding: '15px 30px',
                            borderRadius: '10px',
                            backgroundColor: 'rgba(255, 255, 255, 0.1)',
                            color: 'white',
                            fontWeight: 'bold',
                            boxShadow: '0 3px 5px rgba(245, 102, 7, 0.5)',
                            cursor: 'pointer',
                            border: 'none',
                            textShadow: '1px 1px 4px rgba(0, 0, 0, 0.5)',
                        }}
                        onClick={handleLogin}
                    >
                        Prihlásiť sa
                    </Button>
                    <Button
                        style={{
                            padding: '15px 30px',
                            borderRadius: '10px',
                            backgroundColor: 'rgba(255, 255, 255, 0.1)',
                            color: 'white',
                            fontWeight: 'bold',
                            boxShadow: '0 3px 5px rgba(245, 102, 7, 0.5)',
                            cursor: 'pointer',
                            border: 'none',
                            textShadow: '1px 1px 4px rgba(0, 0, 0, 0.5)',
                            margin: '10px',
                        }}
                        onClick={handleRegister}
                    >
                        Registrovať
                    </Button>
                </div>
            </Modal>

            <LoginModal
                isOpen={isLoginModalOpen}
                onRequestClose={() => setIsLoginModalOpen(false)}
                onLogin={() => {
                    setIsLoginModalOpen(false);
                    navigate('/account');
                }}
            />
            <RegisterModal
                isOpen={isRegisterModalOpen}
                onRequestClose={() => setIsRegisterModalOpen(false)}
                onRegister={() => {
                    setIsRegisterModalOpen(false);
                    navigate('/account');
                }}
            />
        </HeaderContainer>
    );
};

export default Header;
