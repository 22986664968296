import React from 'react';
import styled from 'styled-components';
import Modal from 'react-modal';
import { FaTimes } from 'react-icons/fa';

// Styled Components (unchanged)
const BetModalA = styled.div`
    padding: 30px;
    background: #1a1a1a;
    border-radius: 15px;
    max-width: 500px;
    width: 90%;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5);
    position: relative;
`;

const CloseButton = styled.button`
    position: absolute;
    top: 20px;
    right: 20px;
    background: none;
    border: none;
    color: #f56607;
    font-size: 1.5em;
    cursor: pointer;
    transition: color 0.3s ease;

    &:hover {
        color: #ff8c00;
    }
`;

const ModalTitle = styled.h2`
    color: #f56607;
    margin-bottom: 25px;
    text-align: center;
    font-size: 1.8em;
`;

const FormGroup = styled.div`
    margin-bottom: 20px;

    label {
        display: block;
        margin-bottom: 8px;
        color: rgba(255, 255, 255, 0.85);
        font-size: 1.1em;
    }

    input, select, textarea {
        width: 100%;
        padding: 12px;
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 8px;
        background: #2d2d2d;
        color: white;
        font-size: 1em;
        transition: border-color 0.3s ease;

        &:focus {
            border-color: #f56607;
            outline: none;
        }
    }

    textarea {
        resize: vertical;
        min-height: 100px;
    }
`;

const ButtonGroup = styled.div`
    display: flex;
    gap: 15px;
    margin-top: 25px;
`;

const SubmitButton = styled.button`
    padding: 12px 25px;
    background: #f56607;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1em;
    transition: background-color 0.3s ease;
    flex: 1;

    &:hover {
        background: #ff8c00;
    }
`;

const CancelButton = styled.button`
    padding: 12px 25px;
    background: #6c757d;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1em;
    transition: background-color 0.3s ease;
    flex: 1;

    &:hover {
        background: #5a6268;
    }
`;

// Interface for Bet
interface Bet {
    id: number;
    heading: string;
    description: string;
    eventDate: string;
    odds: number;
    stake: number;
    status: 'vyhraté' | 'prehraté' | 'neodohrané';
}

// Props for BetModal
interface BetModalProps {
    isOpen: boolean;
    onRequestClose: () => void;
    onSubmit: (betData: Bet) => Promise<void>; // Allow async functions
    editingBet?: Bet | null;
}

const BetModal: React.FC<BetModalProps> = ({ isOpen, onRequestClose, onSubmit, editingBet }) => {
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const formData = new FormData(e.currentTarget);
        const betData: Bet = {
            id: editingBet?.id || Date.now(), // Use `id` instead of `post_id`
            heading: formData.get('heading') as string,
            description: formData.get('description') as string,
            odds: parseFloat(formData.get('odds') as string), // Use `odds` instead of `kurz`
            stake: parseFloat(formData.get('stake') as string), // Use `stake` instead of `vklad`
            eventDate: formData.get('eventDate') as string, // Use `eventDate` instead of `datum_stavky`
            status: formData.get('status') as Bet['status'], // Use `status` instead of `stav`
        };
        await onSubmit(betData); // Await the async function
        onRequestClose(); // Close the modal after submission
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            style={{
                overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.75)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 1000,
                },
                content: {
                    position: 'relative',
                    inset: 'auto',
                    padding: '0',
                    borderRadius: '15px',
                    background: 'none',
                    border: 'none',
                    width: '100%',
                    maxWidth: '500px',
                }
            }}
            ariaHideApp={false}
        >
            <BetModalA>
                <CloseButton onClick={onRequestClose}>
                    <FaTimes />
                </CloseButton>

                <ModalTitle>
                    {editingBet ? 'Upraviť stavku' : 'Pridať novú stavku'}
                </ModalTitle>

                <form onSubmit={handleSubmit}>
                    <FormGroup>
                        <label>Názov:</label>
                        <input
                            name="heading"
                            required
                            defaultValue={editingBet?.heading}
                        />
                    </FormGroup>

                    <FormGroup>
                        <label>Popis:</label>
                        <textarea
                            name="description"
                            required
                            defaultValue={editingBet?.description}
                        />
                    </FormGroup>

                    <FormGroup>
                        <label>Kurz:</label>
                        <input
                            type="number"
                            step="0.01"
                            name="odds" // Use `odds` instead of `kurz`
                            required
                            defaultValue={editingBet?.odds}
                        />
                    </FormGroup>

                    <FormGroup>
                        <label>Vklad (jednotky):</label>
                        <input
                            type="number"
                            step="0.01"
                            name="stake" // Use `stake` instead of `vklad`
                            required
                            defaultValue={editingBet?.stake}
                        />
                    </FormGroup>

                    <FormGroup>
                        <label>Dátum:</label>
                        <input
                            type="date"
                            name="eventDate" // Use `eventDate` instead of `datum_stavky`
                            required
                            defaultValue={editingBet?.eventDate}
                        />
                    </FormGroup>

                    <FormGroup>
                        <label>Stav:</label>
                        <select
                            name="status" // Use `status` instead of `stav`
                            defaultValue={editingBet?.status || 'neodohrané'}
                        >
                            <option value="neodohrané">Neodohrané</option>
                            <option value="vyhraté">Vyhraté</option>
                            <option value="prehraté">Prehraté</option>
                        </select>
                    </FormGroup>

                    <ButtonGroup>
                        <CancelButton type="button" onClick={onRequestClose}>
                            Zrušiť
                        </CancelButton>
                        <SubmitButton type="submit">
                            {editingBet ? 'Uložiť zmeny' : 'Pridať stavku'}
                        </SubmitButton>
                    </ButtonGroup>
                </form>
            </BetModalA>
        </Modal>
    );
};

export default BetModal;