import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import Modal from 'react-modal';
import {
    FaEdit, FaPlus, FaTrash, FaTrophy, FaTimesCircle,
    FaChartLine, FaEuroSign, FaChartPie
} from 'react-icons/fa';
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import api from "../api/axiosInstance";
import { RootState } from "../store/store";
import { useSelector } from 'react-redux';
import BetModal from './BetModal';

// Color scheme for the pie chart
const COLORS = ['#28a745', '#dc3545', '#f56607'];

const ActionButton = styled.button`
    background: none;
    border: none;
    cursor: pointer;
    color: #6c757d;
    padding: 5px;
    font-size: 1em;
    transition: color 0.3s ease;

    &:hover {
        color: #f56607;
    }

    @media (max-width: 768px) {
        font-size: 0.9em;
    }
`;

// Styled Components
const BetsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 20px;
    padding: 0 10px;
    background: #0a0a0a;
    min-height: 100vh;
`;

const StatsContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    width: 100%;
    max-width: 1400px;
    margin-bottom: 40px;
    padding: 0 10px;

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
        gap: 15px;
    }
`;

const StatCard = styled.div`
    background: linear-gradient(145deg, #1a1a1a, #2d2d2d);
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 10px 30px rgba(0,0,0,0.3);
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    &:hover {
        transform: translateY(-5px);
        box-shadow: 0 15px 35px rgba(245, 102, 7, 0.2);
    }

    @media (max-width: 768px) {
        padding: 15px;
    }
`;

const ChartCard = styled(StatCard)`
    grid-column: span 2;
    height: 400px;
    margin-top: 40px;

    @media (max-width: 768px) {
        grid-column: span 1;
        height: 300px;
    }
`;

const ProfitCard = styled(StatCard)`
    background: linear-gradient(135deg, #f56607, #ff8c00);
    grid-column: 1 / -1;

    @media (max-width: 768px) {
        grid-column: span 1;
    }
`;

// New styled component for the extra card showing net units won,
// same styling as ProfitCard.
const UnitsCard = styled(StatCard)`
    background: linear-gradient(135deg, #11a80b, #00ff04);
    grid-column: 1 / -1;

    @media (max-width: 768px) {
        grid-column: span 1;
    }
`;

// Styled components for unit input
const UnitInputContainer = styled.div`
    display: flex;
    align-items: center;
    margin: 20px;
    color: white;
    font-size: 1.2em;
`;

const UnitInput = styled.input`
    margin: 0 10px;
    padding: 5px;
    font-size: 1em;
    width: 80px;
    border-radius: 5px;
    border: 1px solid #ccc;
`;

const StatValue = styled.div`
    font-size: 2.2em;
    font-weight: 800;
    margin: 10px 0;
    display: flex;
    align-items: center;
    gap: 10px;
    text-shadow: 0 2px 4px rgba(0,0,0,0.3);

    @media (max-width: 768px) {
        font-size: 1.8em;
    }
`;

const StatLabel = styled.div`
    font-size: 2em;
    color: rgba(255,255,255,0.85);
    text-align: center;
    font-weight: 300;

    @media (max-width: 768px) {
        font-size: 0.9em;
    }
`;

const ChartContainer = styled.div`
    width: 100%;
    height: 100%;
    margin-top: 20px;
`;

const BetTable = styled.div`
    width: 100%;
    max-width: 1400px;
    border-radius: 20px;
    overflow: auto;
    box-shadow: 0 10px 30px rgba(245, 102, 7, 0.2);
    background: #1a1a1a;
    margin-bottom: 50px;

    @media (max-width: 768px) {
        border-radius: 10px;
    }
`;

const BetHeader = styled.div`
    display: grid;
    grid-template-columns: 1fr 2fr 1fr 1fr 1fr 0.5fr;
    padding: 20px;
    background: #2d2d2d;
    color: white;
    font-weight: bold;
    gap: 15px;
    font-size: 1em;
    letter-spacing: 0.5px;

    @media (max-width: 768px) {
        padding: 20px;
        font-size: 1em;
        grid-template-columns: 1fr 2fr 1fr 1fr 1fr 0.5fr;
    }
`;

const BetRow = styled.div<{ status: string }>`
    display: grid;
    grid-template-columns: 1fr 2fr 1fr 1fr 1fr 0.5fr;
    padding: 20px;
    gap: 15px;
    border-bottom: 1px solid rgba(255,255,255,0.05);
    background: ${({ status }) =>
            status === 'vyhraté' ? 'rgba(40, 167, 69, 0.07)' :
                    status === 'prehraté' ? 'rgba(220, 53, 69, 0.07)' :
                            'transparent'};
    transition: all 0.3s ease;
    color: white;

    &:hover {
        background: ${({ status }) =>
                status === 'vyhraté' ? 'rgba(40, 167, 69, 0.15)' :
                        status === 'prehraté' ? 'rgba(220, 53, 69, 0.15)' :
                                'rgba(255,255,255,0.03)'};
    }

    @media (max-width: 768px) {
        padding: 15px;
        font-size: 0.9em;
        grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    }
`;

const StatusCell = styled.span<{ status: string }>`
    color: ${({ status }) =>
            status === 'vyhraté' ? '#28a745' :
                    status === 'prehraté' ? '#dc3545' :
                            '#f56607'};
    font-weight: bold;
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 1em;

    @media (max-width: 768px) {
        font-size: 0.9em;
    }
`;

const AddBetButton = styled.button`
    padding: 12px 25px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 10px;
    margin: 20px 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 1em;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #218838;
    }

    @media (max-width: 768px) {
        padding: 10px 20px;
        font-size: 0.9em;
    }
`;

const ProgressBar = styled.div<{ width: number }>`
    width: 100%;
    height: 6px;
    background: rgba(255,255,255,0.1);
    border-radius: 4px;
    margin-top: 10px;
    overflow: hidden;

    &::after {
        content: '';
        display: block;
        width: ${props => props.width}%;
        height: 100%;
        background: #f56607;
        border-radius: 4px;
        transition: width 0.5s ease-in-out;
    }
`;

// Interface for Bet
interface Bet {
    id: number;
    heading: string;
    description: string;
    eventDate: string;
    odds: number;
    stake: number;
    status: 'vyhraté' | 'prehraté' | 'neodohrané';
}

// Main Component
const BettingTipsList: React.FC = () => {
    const [bets, setBets] = useState<Bet[]>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editingBet, setEditingBet] = useState<Bet | null>(null);
    // State to hold the user-defined value for 1 jednotka (unit)
    const [unitValue, setUnitValue] = useState<number>(50);
    const user = useSelector((state: RootState) => state.auth.user);

    // Fetch all betting tips
    useEffect(() => {
        const fetchBets = async () => {
            try {
                const response = await api.get('/betting-tips');
                setBets(response.data);
            } catch (error) {
                console.error('Error fetching bets:', error);
            }
        };

        fetchBets();
    }, []);

    // Calculate statistics using the user-defined unitValue
    const stats = useMemo(() => {
        const total = bets.length;
        const wins = bets.filter(b => b.status === 'vyhraté').length;
        const losses = bets.filter(b => b.status === 'prehraté').length;
        const pending = total - wins - losses;

        const profit = bets.reduce((acc, bet) => {
            if (bet.status === 'vyhraté') return acc + (bet.odds * bet.stake * unitValue) - (bet.stake * unitValue);
            if (bet.status === 'prehraté') return acc - (bet.stake * unitValue);
            return acc;
        }, 0);

        return {
            total,
            wins,
            losses,
            pending,
            winRate: total > 0 ? (wins / total * 100) : 0,
            lossRate: total > 0 ? (losses / total * 100) : 0,
            profit,
            chartData: [
                { name: 'Vyhraté', value: wins, color: '#28a745' },
                { name: 'Prehraté', value: losses, color: '#dc3545' },
                { name: 'Neodohrané', value: pending, color: '#f56607' }
            ]
        };
    }, [bets, unitValue]);

    // Calculate net units won (without the euro multiplier)
    const netUnits = useMemo(() => {
        return bets.reduce((acc, bet) => {
            if (bet.status === 'vyhraté') return acc + ((bet.odds - 1) * bet.stake);
            if (bet.status === 'prehraté') return acc - bet.stake;
            return acc;
        }, 0);
    }, [bets]);

    // Handle form submission
    const handleSubmit = async (betData: Bet) => {
        try {
            if (editingBet) {
                // Update existing bet
                const response = await api.put(`/betting-tips/${betData.id}`, betData);
                setBets(bets.map(b => b.id === betData.id ? response.data : b));
            } else {
                // Create new bet
                const response = await api.post('/betting-tips', betData);
                setBets([...bets, response.data]);
            }
            setIsModalOpen(false);
            setEditingBet(null);
        } catch (error) {
            console.error('Error saving bet:', error);
        }
    };

    // Handle delete
    const handleDelete = async (id: number) => {
        try {
            await api.delete(`/betting-tips/${id}`);
            setBets(bets.filter(bet => bet.id !== id));
        } catch (error) {
            console.error('Error deleting bet:', error);
        }
    };

    // Custom Tooltip for Pie Chart
    const CustomTooltip = ({ active, payload }: any) => {
        if (active && payload && payload.length) {
            return (
                <div style={{
                    background: '#1a1a1a',
                    padding: '10px',
                    border: '1px solid #f56607',
                    borderRadius: '8px'
                }}>
                    <p style={{ color: payload[0].payload.color, margin: 0 }}>
                        {payload[0].name}: {payload[0].value}
                    </p>
                </div>
            );
        }
        return null;
    };

    return (
        <BetsContainer>
            {/* Unit Input Section */}

            {/* Statistics Section */}
            <StatsContainer>
                <ChartCard>
                    <StatLabel>
                        <FaChartPie style={{ marginRight: 10 }} />
                        Databáza tipov
                    </StatLabel>
                    <ChartContainer>
                        <ResponsiveContainer width="100%" height="100%">
                            <PieChart>
                                <Pie
                                    data={stats.chartData}
                                    cx="50%"
                                    cy="50%"
                                    innerRadius={60}
                                    outerRadius={120}
                                    paddingAngle={5}
                                    dataKey="value"
                                >
                                    {stats.chartData.map((entry, index) => (
                                        <Cell
                                            key={`cell-${index}`}
                                            fill={entry.color}
                                            stroke="#1a1a1a"
                                            strokeWidth={2}
                                        />
                                    ))}
                                </Pie>
                                <Legend
                                    iconSize={15}
                                    wrapperStyle={{ paddingTop: '20px' }}
                                    formatter={(value) => (
                                        <span style={{ color: '#fff', fontSize: '14px' }}>{value}</span>
                                    )}
                                />
                                <Tooltip content={<CustomTooltip />} />
                            </PieChart>
                        </ResponsiveContainer>
                    </ChartContainer>
                </ChartCard>

                <StatCard>
                    <FaTrophy size={24} color="#28a745" />
                    <StatValue>
                        {stats.wins}
                        <span style={{ fontSize: '0.6em', color: 'rgba(255,255,255,0.7)' }}>
                            / {stats.total}
                        </span>
                    </StatValue>
                    <StatLabel>Vyhraté tipy</StatLabel>
                    <ProgressBar width={stats.winRate} />
                </StatCard>

                <StatCard>
                    <FaTimesCircle size={24} color="#dc3545" />
                    <StatValue>
                        {stats.losses}
                        <span style={{ fontSize: '0.6em', color: 'rgba(255,255,255,0.7)' }}>
                            / {stats.total}
                        </span>
                    </StatValue>
                    <StatLabel>Prehraté tipy</StatLabel>
                    <ProgressBar width={stats.lossRate} />
                </StatCard>

                {/* Units Won Card */}
                <UnitsCard>
                    <FaChartLine size={24} color="#fff" />
                    <StatValue>
                        {netUnits >= 0 ? '+' : ''}
                        {netUnits.toFixed(2)}
                    </StatValue>
                    <StatLabel>Vyhraté jednotky</StatLabel>
                </UnitsCard>

                <UnitInputContainer>
                    <span>1 Jednotka =</span>
                    <UnitInput
                        type="number"
                        value={unitValue}
                        onChange={(e) => setUnitValue(Number(e.target.value))}
                    />
                    <span>€</span>
                </UnitInputContainer>

                {/* Profit Card */}
                <ProfitCard>
                    <FaEuroSign size={24} color="#fff" />
                    <StatValue>
                        {stats.profit >= 0 ? '+' : ''}
                        {stats.profit.toFixed(2)}€
                    </StatValue>
                    <StatLabel>Celkový profit pri vklade 1 jednotka = {unitValue}€</StatLabel>
                </ProfitCard>

            </StatsContainer>

            {/* Bet Table Section */}
            <BetTable>
                <BetHeader>
                    <span>Dátum</span>
                    <span>Popis</span>
                    <span>Kurz</span>
                    <span>Vklad (j)</span>
                    <span>Stav</span>
                    <span></span>
                </BetHeader>

                {bets.map(bet => (
                    <BetRow key={bet.id} status={bet.status}>
                        <span>{new Date(bet.eventDate).toLocaleDateString('sk-SK')}</span>
                        <span>{bet.heading}</span>
                        <span>{bet.odds}</span>
                        <span>{bet.stake}j</span>
                        <StatusCell status={bet.status}>
                            {bet.status === 'vyhraté' ? <FaTrophy /> :
                                bet.status === 'prehraté' ? <FaTimesCircle /> :
                                    <FaChartLine />}
                            {bet.status}
                        </StatusCell>
                        <div>
                            {user?.isAdmin && (
                                <>
                                    <ActionButton onClick={() => {
                                        setEditingBet(bet);
                                        setIsModalOpen(true);
                                    }}>
                                        <FaEdit />
                                    </ActionButton>
                                    <ActionButton onClick={() => handleDelete(bet.id)}>
                                        <FaTrash />
                                    </ActionButton>
                                </>
                            )}
                        </div>
                    </BetRow>
                ))}
            </BetTable>

            {/* Add Bet Button */}
            {user?.isAdmin && (
                <AddBetButton onClick={() => setIsModalOpen(true)}>
                    <FaPlus /> Pridať novú stavku
                </AddBetButton>
            )}

            {/* Bet Modal */}
            <BetModal
                isOpen={isModalOpen}
                onRequestClose={() => {
                    setIsModalOpen(false);
                    setEditingBet(null);
                }}
                onSubmit={handleSubmit}
                editingBet={editingBet}
            />
        </BetsContainer>
    );
};

export default BettingTipsList;