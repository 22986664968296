// UpdatePaymentMethod.tsx
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { useSelector } from 'react-redux';
import { RootState } from '../store/store';
import api from '../api/axiosInstance';

const ModalWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
`;

const ModalContent = styled.div`
    background: #fff;
    padding: 24px;
    border-radius: 8px;
    width: 400px;
    max-width: 90%;
`;

const Title = styled.h2`
    text-align: center;
    margin-bottom: 16px;
`;

const Button = styled.button`
    background-color: #f56607;
    color: #fff;
    padding: 12px 24px;
    border: none;
    border-radius: 8px;
    width: 100%;
    margin-top: 16px;
    cursor: pointer;
    transition: background-color 0.2s ease;
    &:hover {
        background-color: #d75405;
    }
    &:disabled {
        background-color: #ccc;
        cursor: not-allowed;
    }
`;

const CloseButton = styled(Button)`
    background-color: #555;
    margin-top: 8px;
`;

const CardElementWrapper = styled.div`
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 16px;
`;

interface UpdatePaymentMethodProps {
    onClose: () => void;
}

const UpdatePaymentMethod: React.FC<UpdatePaymentMethodProps> = ({ onClose }) => {
    const stripe = useStripe();
    const elements = useElements();
    const user = useSelector((state: RootState) => state.auth.user);
    const [clientSecret, setClientSecret] = useState<string | null>(null);
    const [message, setMessage] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    // Získajte SetupIntent clientSecret z backendu.
    const fetchSetupIntent = async () => {
        try {
            const response = await api.post('setup-intent', { userId: user?.id });
            setClientSecret(response.data.clientSecret);
        } catch (error) {
            console.error('Chyba pri získavaní SetupIntent:', error);
            setMessage('Nepodarilo sa inicializovať aktualizáciu platobnej metódy.');
        }
    };

    useEffect(() => {
        fetchSetupIntent();
    }, []);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!stripe || !elements || !clientSecret) return;
        setLoading(true);
        const cardElement = elements.getElement(CardElement);
        if (!cardElement) {
            setMessage('Card Element sa nenašiel.');
            setLoading(false);
            return;
        }

        const { error, setupIntent } = await stripe.confirmCardSetup(clientSecret, {
            payment_method: {
                card: cardElement,
                billing_details: { name: user?.fullName || '' },
            },
        });

        if (error) {
            console.error('Chyba pri potvrdení nastavenia karty:', error);
            setMessage(error.message || 'Chyba pri aktualizácii platobnej metódy.');
        } else if (setupIntent) {
            const newPaymentMethodId = setupIntent.payment_method;
            try {
                // Call endpoint for updating the default payment method.
                await api.post('setup-intent/update-default', {
                    userId: user?.id,
                    paymentMethodId: newPaymentMethodId,
                });
                setMessage('Platobná metóda bola úspešne aktualizovaná!');
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            } catch (err) {
                console.error('Chyba pri aktualizácii predvolenej platobnej metódy:', err);
                setMessage('Chyba pri aktualizácii predvolenej platobnej metódy.');
            }
        }
        setLoading(false);
    };

    return (
        <ModalWrapper>
            <ModalContent>
                <Title>Aktualizácia platobnej metódy</Title>
                {message && <p>{message}</p>}
                <form onSubmit={handleSubmit}>
                    <CardElementWrapper>
                        <CardElement options={{ hidePostalCode: true }} />
                    </CardElementWrapper>
                    <Button type="submit" disabled={!stripe || loading}>
                        {loading ? 'Aktualizácia...' : 'Aktualizovať platobnú metódu'}
                    </Button>
                </form>
                <CloseButton onClick={onClose}>Zavrieť</CloseButton>
            </ModalContent>
        </ModalWrapper>
    );
};

export default UpdatePaymentMethod;