import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, StripeElementsOptions } from '@stripe/stripe-js';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, AppDispatch } from '../store/store';
import { logout } from '../reducers/authSlice';
import { useNavigate } from 'react-router-dom';
import NotificationModal from '../components/NotificationModal';
import CookiesModal from '../components/CookiesModal';
import DeleteAccountModal from '../components/DeleteAccountModal';
import BuyPremium from '../components/BuyPremium';
import api from '../api/axiosInstance';

const stripePromise = loadStripe('pk_live_51PexFn2LxS9rV2S4jEp30qRFF8DK5Crr6KxDD3NsnEgLrl12PZQ7hCENsCKyLf7O6TIuSMfNbnjfVHeGk0Osh2EW00Rf4hdjgi');

const AccountContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 600px;
    margin: 10px auto;
    padding: 20px;
    background-color: #f8f9fa;
`;

const PremiumButton = styled.button`
    background-color: #f56607;
    color: white;
    padding: 15px;
    border-radius: 10px;
    border: none;
    width: 100%;
    font-size: 16px;
    font-weight: bold;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    cursor: pointer;
`;

const FullWidthButton = styled.button`
    background-color: #fff;
    color: #333;
    padding: 15px;
    border-radius: 10px;
    border: 1px solid #ddd;
    width: 100%;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    margin-bottom: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

    &:hover {
        background-color: #f0f0f0;
    }
`;

const LogoutButton = styled(FullWidthButton)`
    background-color: #ff4d4d;
    color: white;

    &:hover {
        background-color: #ff0000;
    }
`;

const Divider = styled.div`
    width: 80%;
    height: 1px;
    background-color: #ccc;
    margin: 20px 0;
`;

const SmallLinkContainer = styled.div`
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
`;

const SmallLink = styled.a`
    font-size: 0.8em;
    color: #007bff;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
`;

const Account: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const [isPremiumModalOpen, setPremiumModalOpen] = useState(false);
    const [paymentOptions, setPaymentOptions] = useState<StripeElementsOptions | undefined>(undefined);
    const [isNotificationModalOpen, setNotificationModalOpen] = useState(false);
    const [isCookiesModalOpen, setCookiesModalOpen] = useState(false);
    const [isDeleteAccountModalOpen, setDeleteAccountModalOpen] = useState(false);

    const user = useSelector((state: RootState) => state.auth.user);
    const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);

    const handleLogout = () => {
        dispatch(logout());
    };

    const openPremiumModal = () => {
        setPremiumModalOpen(true);
    };

    const closePremiumModal = () => {
        setPremiumModalOpen(false);
    };

    const openNotificationModal = () => {
        setNotificationModalOpen(true);
    };

    const closeNotificationModal = () => {
        setNotificationModalOpen(false);
    };

    const openCookiesModal = () => {
        setCookiesModalOpen(true);
    };

    const closeCookiesModal = () => {
        setCookiesModalOpen(false);
    };

    const openDeleteAccountModal = () => {
        setDeleteAccountModalOpen(true);
    };

    const closeDeleteAccountModal = () => {
        setDeleteAccountModalOpen(false);
    };

    const handleDeleteAccount = async () => {
        try {
            await api.delete(`/account/delete/${user?.id}`);
            dispatch(logout());
            navigate('/');
        } catch (error) {
            console.error('Error deleting account:', error);
        }
    };

    // Create payment options and save clientSecret in local storage if available.
    const createPaymentOptions = async () => {
        try {
            const response = await api.post(`/payment-intent/${user?.id}`, { method: 'POST' });
            const data = response.data;
            // Ak clientSecret nie je prázdny, uložte ho do localStorage a nastavte možnosti.
            if (data.clientSecret && data.clientSecret.trim() !== "") {
                localStorage.setItem("clientSecret", data.clientSecret);
                const options: StripeElementsOptions = {
                    clientSecret: data.clientSecret,
                    appearance: {
                        theme: 'night',
                        variables: {
                            fontFamily: 'Roboto, sans-serif',
                            fontSizeBase: '16px',
                            colorPrimary: '#ffffff',
                            colorBackground: '#000',
                            colorText: '#f56607',
                            colorTextPlaceholder: '#888',
                            colorTextSecondary: '#FFA500',
                            colorDanger: '#FF4D4D',
                            borderRadius: '8px',
                        },
                        rules: {
                            '.Input': {
                                color: '#FFFFFF',
                            },
                        },
                    },
                };
                setPaymentOptions(options);
            } else {
                // Ak je clientSecret prázdny, skúste načítať zo localStorage
                const storedSecret = localStorage.getItem("clientSecret");
                if (storedSecret) {
                    const options: StripeElementsOptions = {
                        clientSecret: storedSecret,
                        appearance: {
                            theme: 'night',
                            variables: {
                                fontFamily: 'Roboto, sans-serif',
                                fontSizeBase: '16px',
                                colorPrimary: '#ffffff',
                                colorBackground: '#000',
                                colorText: '#f56607',
                                colorTextPlaceholder: '#888',
                                colorTextSecondary: '#FFA500',
                                colorDanger: '#FF4D4D',
                                borderRadius: '8px',
                            },
                            rules: {
                                '.Input': {
                                    color: '#FFFFFF',
                                },
                            },
                        },
                    };
                    setPaymentOptions(options);
                } else {
                    console.warn("Client secret je prázdny a nebol nájdený v localStorage, skúšam znova...");
                    await createPaymentOptions();
                }
            }
        } catch (error) {
            console.error('Error creating payment options:', error);
        }
    };

    useEffect(() => {
        if (user) {
            createPaymentOptions();
        }
    }, [user]);

    return (
        <AccountContainer>
            {user?.isPremium ? (
                <PremiumButton disabled>Prémium Aktivované</PremiumButton>
            ) : (
                <PremiumButton onClick={openPremiumModal}>KÚPIŤ PRÉMIUM</PremiumButton>
            )}
            <PremiumButton onClick={() => navigate('/predplatne')}>Členstvo</PremiumButton>
            <FullWidthButton onClick={() => navigate('/aplikacia')}>Stiahnuť Aplikáciu</FullWidthButton>
            <Divider />
            <FullWidthButton onClick={() => navigate('/profile-settings')}>Nastavenia profilu</FullWidthButton>
            <FullWidthButton onClick={() => navigate('/order-history')}>História objednávok</FullWidthButton>
            <FullWidthButton onClick={openNotificationModal}>Upozornenia</FullWidthButton>
            <Divider />
            {isAuthenticated && (
                <LogoutButton onClick={handleLogout}>Odhlásiť sa</LogoutButton>
            )}
            <SmallLinkContainer>
                <SmallLink href="/sample.pdf" target="_blank" rel="noopener noreferrer">Podmienky použivania</SmallLink>
                <SmallLink onClick={openCookiesModal}>Nastavenia cookies</SmallLink>
                <SmallLink onClick={openDeleteAccountModal}>Odstraniť účet</SmallLink>
            </SmallLinkContainer>
            {paymentOptions?.clientSecret && (
                <Elements stripe={stripePromise} options={paymentOptions}>
                    <BuyPremium
                        isOpen={isPremiumModalOpen}
                        onClose={closePremiumModal}
                        stripePromise={stripePromise}
                        paymentOptions={paymentOptions}
                    />
                </Elements>
            )}
            <NotificationModal
                isOpen={isNotificationModalOpen}
                onRequestClose={closeNotificationModal}
                onSettingsUpdate={() => { /* Handle settings update here */ }}
            />
            <CookiesModal
                isOpen={isCookiesModalOpen}
                onRequestClose={closeCookiesModal}
            />
            <DeleteAccountModal
                isOpen={isDeleteAccountModalOpen}
                onRequestClose={closeDeleteAccountModal}
                onDeleteAccount={handleDeleteAccount}
            />
        </AccountContainer>
    );
};

export default Account;