import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useSelector } from 'react-redux';
import { RootState } from '../store/store';
import api from '../api/axiosInstance';
import UpdatePaymentMethod from './UpdatePaymentMethod';

interface PaymentMethodDto {
    id: string;
    cardBrand: string;
    cardLast4: string;
    expMonth: number;
    expYear: number;
}

interface SubscriptionDetails {
    id: string;
    status: string;
    currentPeriodEnd: number; // Unix timestamp in seconds
    paymentMethod?: PaymentMethodDto;
}

const stripePromise = loadStripe('pk_live_51PexFn2LxS9rV2S4jEp30qRFF8DK5Crr6KxDD3NsnEgLrl12PZQ7hCENsCKyLf7O6TIuSMfNbnjfVHeGk0Osh2EW00Rf4hdjgi');

const Container = styled.div`
    padding: 20px;
    background: #f8f9fa;
    border-radius: 8px;
    max-width: 600px;
    margin: 20px auto;
`;

const Title = styled.h2`
    margin-bottom: 20px;
    text-align: center;
`;

const InfoRow = styled.div`
    margin-bottom: 16px;
    font-size: 1rem;
    color: #333;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const Label = styled.span`
    font-weight: bold;
`;

const StatusBadge = styled.div<{ status: string }>`
    background: ${({ status }) =>
            status.toLowerCase() === 'active' ? '#28a745' : '#dc3545'};
    color: #fff;
    font-weight: bold;
    padding: 6px 12px;
    border-radius: 12px;
    font-size: 1rem;
`;

const Button = styled.button`
    background-color: #f56607;
    color: white;
    padding: 12px 24px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1rem;
    width: 100%;
    margin-top: 24px;
    transition: background-color 0.2s ease;

    &:hover {
        background-color: #d75405;
    }

    &:disabled {
        background-color: #ccc;
        cursor: not-allowed;
    }
`;

// Virtual card styling to mimic a physical credit card appearance.
const VirtualCardWrapper = styled.div`
    background: linear-gradient(135deg, #667eea, #764ba2);
    border-radius: 16px;
    width: 320px;
    height: 200px;
    color: #fff;
    padding: 20px;
    position: relative;
    font-family: 'Courier New', Courier, monospace;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    margin: 20px auto;
`;

const CardBrand = styled.div`
    font-size: 1rem;
    font-weight: bold;
`;

const CardNumber = styled.div`
    font-size: 1rem;
    letter-spacing: 4px;
    margin-top: 20px;
    text-align: center;
    white-space: nowrap;
`;

const Expiration = styled.div`
    font-size: 1rem;
    margin-top: 20px;
    text-align: left;
`;

const CardHolder = styled.div`
    font-size: 1rem;
    position: absolute;
    bottom: 20px;
    left: 20px;
    text-align: left;
`;

const VirtualCard: React.FC<{ paymentMethod: PaymentMethodDto; cardHolderName?: string }> = ({
                                                                                                 paymentMethod,
                                                                                                 cardHolderName,
                                                                                             }) => {
    return (
        <VirtualCardWrapper>
            <CardBrand>{paymentMethod.cardBrand.toUpperCase()}</CardBrand>
            <CardNumber>**** **** **** {paymentMethod.cardLast4}</CardNumber>
            <Expiration>
                Exp: {paymentMethod.expMonth.toString().padStart(2, '0')}/{paymentMethod.expYear}
            </Expiration>
            {cardHolderName && <CardHolder>{cardHolderName}</CardHolder>}
        </VirtualCardWrapper>
    );
};

const SubscriptionManager: React.FC = () => {
    const user = useSelector((state: RootState) => state.auth.user);
    const [subscription, setSubscription] = useState<SubscriptionDetails | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [canceling, setCanceling] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [showUpdateModal, setShowUpdateModal] = useState<boolean>(false);

    const fetchSubscription = async () => {
        if (!user?.id) {
            setError("Používateľ sa nenašiel");
            setLoading(false);
            return;
        }
        try {
            const response = await api.get(`/subscription/${user.id}`);
            setSubscription(response.data);
        } catch (err) {
            setError("Chyba pri načítavaní údajov o predplatnom");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchSubscription();
    }, [user]);

    const handleCancelSubscription = async () => {
        if (!window.confirm("Naozaj chcete zrušiť predplatné?")) {
            return;
        }
        setCanceling(true);
        try {
            await api.post(`/subscription/cancel/${user?.id}`);
            await fetchSubscription();
        } catch (err) {
            setError("Chyba pri rušení predplatného");
        } finally {
            setCanceling(false);
        }
    };

    if (loading) {
        return <Container>Načítavajú sa údaje o predplatnom...</Container>;
    }

    if (error) {
        return <Container>Momentálne nemáte aktivované členstvo.</Container>;
    }

    return (
        <Container>
            <Title>Správa predplatného</Title>
            {subscription ? (
                <>
                    <InfoRow>
                        <Label>Stav:</Label>
                        <StatusBadge status={subscription.status}>
                            {subscription.status.toUpperCase()}
                        </StatusBadge>
                    </InfoRow>
                    {subscription.status.toLowerCase() === 'active' && (
                        <InfoRow>
                            <Label>Ďalší dátum fakturácie:</Label>
                            <span>{new Date(subscription.currentPeriodEnd * 1000).toLocaleString()}</span>
                        </InfoRow>
                    )}
                    {subscription.paymentMethod && (
                        <VirtualCard
                            paymentMethod={subscription.paymentMethod}
                            cardHolderName={user?.fullName}
                        />
                    )}
                    {subscription.status.toLowerCase() === 'active' ? (
                        <>
                            <Button onClick={handleCancelSubscription} disabled={canceling}>
                                {canceling ? "Ruší sa..." : "Zrušiť predplatné"}
                            </Button>
                            <Button
                                onClick={() => setShowUpdateModal(true)}
                                style={{ marginTop: '12px', backgroundColor: '#007bff' }}
                            >
                                Zmeniť platobnú metódu
                            </Button>
                        </>
                    ) : (
                        <InfoRow>Vaše predplatné nie je aktívne.</InfoRow>
                    )}
                </>
            ) : (
                <InfoRow>Žiadne údaje o predplatnom nie sú k dispozícii.</InfoRow>
            )}
            {showUpdateModal && (
                <Elements stripe={stripePromise}>
                    <UpdatePaymentMethod onClose={() => setShowUpdateModal(false)} />
                </Elements>
            )}
        </Container>
    );
};

export default SubscriptionManager;